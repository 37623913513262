// jQueryPlugin.js
import jQuery from 'jquery'

export default {
  install: () => {
    if (typeof window !== 'undefined') {
      // Make jQuery available globally to ensure Fomantic UI can find it
      window.jQuery = jQuery
      window.$ = jQuery
    }
    // This helps debug when jQuery is loaded
    // console.info('jQuery plugin initialized: version', jQuery.fn.jquery)
    return jQuery
  }
}