/**
 * Composable for adding a custom waiting screen to Cloudflow viewer URLs
 * Uses the waitingScreenUrl parameter as documented in Cloudflow API
 */
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'

export function useWaitingScreen() {
  const route = useRoute()
  
  // Get the base URL for the waiting screen, based on the current route
  const waitingScreenPath = computed(() => {
    // Build the absolute path to our waiting screen
    const baseUrl = window.location.origin
    const path = '/cloudflow/waiting-screen.html'
    return `${baseUrl}${path}`
  })

  /**
   * Append the waiting screen URL parameter to a Cloudflow viewer URL
   * 
   * @param {string} url - The original Cloudflow viewer URL
   * @returns {string} - URL with the waitingScreenUrl parameter appended
   */
  const appendWaitingScreen = (url) => {
    if (!url) return url

    try {
      // Encode the waiting screen path
      const encodedWaitingScreen = encodeURIComponent(waitingScreenPath.value)
      
      // Check if URL already has parameters
      const separator = url.includes('?') ? '&' : '?'
      
      // Append the waitingScreenUrl parameter
      return `${url}${separator}waitingScreenUrl=${encodedWaitingScreen}`
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error appending waiting screen URL:', error)
      
      return url
    }
  }

  return {
    waitingScreenPath,
    appendWaitingScreen
  }
}