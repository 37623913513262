<template>
  <div class="login-container">
    <div class="login-card">
      <div class="logo-container">
        <img
          class="logo"
          src="/logo.svg"
          alt="Logo"
        />
      </div>

      <div
        v-if="!backendHealthy"
        class="alert-message"
      >
        <div class="alert-header">Backend is not responding</div>
        <p>Please try again later or contact support.</p>
      </div>

      <div class="form-container">
        <div class="input-group">
          <div class="input-icon">
            <i class="user icon"></i>
          </div>
          <input
            v-model="formData.email"
            type="email"
            name="email"
            placeholder="E-mail address"
            autocomplete="email"
            @keyup.enter="submitForm"
          />
        </div>

        <div class="input-group">
          <div class="input-icon">
            <i class="lock icon"></i>
          </div>
          <input
            v-model="formData.password"
            type="password"
            name="password"
            placeholder="Password"
            autocomplete="current-password"
            @keyup.enter="submitForm"
          />
        </div>

        <button
          class="login-button"
          type="submit"
          :disabled="!isFormValid || loading"
          data-test="login-button"
          @click.prevent="submitForm"
        >
          <i
            v-if="loading"
            class="spinner loading icon"
          ></i>
          <span>{{ loading ? 'Logging in...' : 'Login' }}</span>
        </button>

        <div
          v-if="azureClientId"
          class="divider"
        >
          <span>Or</span>
        </div>

        <button
          v-if="azureClientId"
          class="microsoft-button"
          type="button"
          :disabled="loading"
          @click="loginAzure"
        >
          <i class="microsoft icon"></i>
          <span>Login with Microsoft</span>
        </button>

        <div
          v-if="errors.length"
          class="error-container"
        >
          <div
            v-for="error in errors"
            :key="error.message"
            class="error-message"
          >
            {{ error.message }}
          </div>
        </div>
      </div>
    </div>

    <div class="background-decoration"></div>
  </div>
</template>

<script setup>
import '@/assets/auth.scss'

import {ref, computed, onMounted} from 'vue'
import {useAuth} from '@/composables/useAuth'
import backendApiClient from '@/services/backendApiClient'
import auth from '@/services/azureAuth'
import azureGraph from '@/services/azureGraph'
import {useUserStore} from '@/stores/user' // Import statically

const {loading, errors, loginWithCredentials, loginWithAzure} = useAuth()

// Development defaults with lazy evaluation
const isDevelopment = import.meta.env.MODE === 'local'
const formData = ref({
  email: isDevelopment ? 'fridzema@volkers.nl' : '',
  password: isDevelopment ? 'password' : '',
  device_name: 'web-app'
})

const backendHealthy = ref(true)
// const healthCheckInterval = null // Removed as interval is disabled

// Authentication success state for animation

const isFormValid = computed(() => formData.value.email && formData.value.password)
const azureClientId = computed(() => import.meta.env.VITE_AZURE_AD_CLIENT_ID)

// Check if we're returning from a redirect and handle authentication
const handleAzureRedirect = async () => {
  if (!azureClientId.value) return

  try {
    loading.value = true

    // eslint-disable-next-line no-console
    console.log('Handling Azure redirect...')

    // Initialize Azure auth and handle redirect
    // Configuration is already done in onMounted

    // Handle redirect response if present
    let response
    try {
      response = await auth.handleRedirectPromise()
    } catch (redirectError) {
      // eslint-disable-next-line no-console
      console.error('Azure redirect error:', redirectError)
      errors.value.push({
        message: `Azure redirect error: ${redirectError.message || 'Unknown error'}`
      })
      return
    }

    if (response) {
      try {
        // Get user profile from Graph API
        const userProfile = await azureGraph.getProfile()

        // Get Azure access token
        const accessToken = await azureGraph.getAccessToken()

        // Get user store to update authentication state
        const userStore = useUserStore() // Use statically imported store

        // Update user store with Azure user info
        if (userProfile && userProfile.mail) {
          // Authenticate with backend using standard login endpoint
          try {
            await userStore.signIn({
              email: userProfile.mail,
              device_name: 'web-app',
              password: accessToken,
              access_token: accessToken
            })

            // Redirect immediately after successful login
            // Set a flag in sessionStorage to indicate successful login
            sessionStorage.setItem('just_authenticated', 'true')

            // Make sure the intended path is preserved in localStorage
            // This ensures the redirect works properly after authentication
            const intendedPath = localStorage.getItem('auth_redirect_path')
            if (intendedPath) {
              // eslint-disable-next-line no-console
              console.log('[Login] 🔍 Preserving intended path for Azure auth:', intendedPath)

              // Set a flag to indicate we have a redirect path
              window.__hasRedirectPath = true
            }

            // Use window.location for a full page reload, but with the flag set
            window.location = window.location.origin
          } catch (signInError) {
            // eslint-disable-next-line no-console
            console.error('Backend authentication error:', signInError)
            errors.value.push({
              message: 'Authentication failed. Please contact support.'
            })
          }
        } else {
          errors.value.push({
            message: 'No email found in Azure profile. Please contact support.'
          })
        }
      } catch (profileError) {
        // eslint-disable-next-line no-console
        console.error('Azure profile error:', profileError)
        errors.value.push({
          message: `Failed to get Azure profile: ${profileError.message || 'Unknown error'}`
        })
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Azure authentication error:', err)
    errors.value.push({
      message: `Azure authentication error: ${err.message || 'Unknown error'}`
    })
  } finally {
    loading.value = false
  }
}

// Health check implementation with AbortController for better performance
const healthCheck = async () => {
  try {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), 5000)

    const res = await backendApiClient.get('/up', {
      signal: controller.signal
    })

    clearTimeout(timeoutId)
    backendHealthy.value = res.status === 200
  } catch (error) {
    if (error.name !== 'AbortError') {
      backendHealthy.value = false
    }
  }
}

onMounted(async () => {
  // Configure Azure auth only if client ID exists
  if (azureClientId.value) {
    // Initialize Azure auth first
    await auth.configure(azureClientId.value)

    // Then handle potential redirect responses
    await handleAzureRedirect()
  }

  // Initial health check
  healthCheck()

  // Removed interval health check for performance
  // healthCheckInterval = setInterval(healthCheck, 10000)
})

// onBeforeUnmount(() => {
//   if (healthCheckInterval) {
//     clearInterval(healthCheckInterval)
//     healthCheckInterval = null
//   }
// })

const submitForm = async () => {
  if (!isFormValid.value || loading.value) return

  try {
    // eslint-disable-next-line no-console
    console.log('Attempting login with credentials...')

    const success = await loginWithCredentials(formData.value)
    if (success) {
      // eslint-disable-next-line no-console
      console.log('Login successful, showing animation...')

      // Redirect immediately after successful login
      // Set flag in sessionStorage for post-login handling
      // eslint-disable-next-line no-console
      console.log('[Login] 🎉 Authentication successful, redirecting...')
      sessionStorage.setItem('just_authenticated', 'true')

      // Make sure the intended path is preserved in localStorage
      // This ensures the redirect works properly after authentication
      const intendedPath = localStorage.getItem('auth_redirect_path')
      if (intendedPath) {
        // eslint-disable-next-line no-console
        console.log('[Login] 🔍 Preserving intended path:', intendedPath)

        // Set a flag to indicate we have a redirect path
        window.__hasRedirectPath = true
      }

      // Reload the page to complete authentication
      // The main.js and authenticated.js will handle the redirect based on localStorage
      // eslint-disable-next-line no-console
      console.log('[Login] 🔄 Reloading page to complete authentication')
      window.location = window.location.origin
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Login error:', error)
    errors.value.push({
      message: `Login failed: ${error.message || 'Unknown error'}`
    })
  }
}

const loginAzure = async () => {
  if (loading.value) return

  try {
    // eslint-disable-next-line no-console
    console.log('Initiating Azure login...')
    await loginWithAzure()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Azure login error:', error)
    errors.value.push({
      message: `Azure login failed: ${error.message || 'Unknown error'}`
    })
  }
}
</script>
