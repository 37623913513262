<template>
  <div class="approval-page">
    <!-- Loader -->
    <div
      v-if="state.loading"
      class="page-loader-container"
    >
      <div class="ui active dimmer">
        <div class="ui large text loader">Loading...</div>
      </div>
    </div>

    <template v-else>
      <!-- Error -->
      <div
        v-if="state.error"
        class="error-container"
      >
        <div class="ui negative message">
          <div class="header">Error</div>
          <p>{{ state.error }}</p>
        </div>
      </div>

      <!-- Main content -->
      <template v-else>
        <div class="sidebar-container">
          <Sidebar
            :disabled="state.loading"
            :current-view-type="currentViewType"
            :approval-data="state.data"
            :current-asset-data="currentAssetData"
            :is-submitting="state.submitting"
            :is-submitted="state.isSubmitted"
            :selected-action="selectedAction"
            :comment="comment"
            :available-actions="state.actions"
            @fetch-single="handleFetchSingle"
            @fetch-step-repeat="handleFetchStepRepeat"
            @update-selected-action="selectedAction = $event"
            @update-comment="comment = $event"
            @submit-action="submitAction"
          />
        </div>
        <div class="viewer-container">
          <Viewer
            v-if="viewerUrl"
            :viewer-url="viewerUrl"
          />
          <div
            v-else
            class="ui placeholder segment"
          >
            <div
              class="ui icon header"
              aria-label="No document preview available"
            >
              <i
                class="file outline icon"
                aria-hidden="true"
              ></i>
              No document preview available
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script setup>
import {ref, computed, defineAsyncComponent, onMounted, onUnmounted, watch} from 'vue'
import {useDocumentVisibility} from '@vueuse/core'
import {useRoute} from 'vue-router'
import {storeImports} from '@/stores/storeImports'
import {useWaitingScreen} from '@/composables/useWaitingScreen'
import {useApproval} from '@/composables/useApproval'

const Sidebar = defineAsyncComponent(() => import('./Partials/Sidebar.vue'))
const Viewer = defineAsyncComponent(() => import('./Partials/Viewer.vue'))

const {appendWaitingScreen} = useWaitingScreen()

const route = useRoute()
const visibility = useDocumentVisibility()

const userStore = ref(null)
const currentViewType = ref('single')

// Approval composable
const {state, loadApprovalData, submitApprovalAction, startPolling, stopPolling} = useApproval(
  currentViewType,
  userStore
)

// UI state unrelated to approval API
const selectedAction = ref('')
const comment = ref('')
const isActionFormOpen = ref(true)
const cssImportsLoaded = ref(false)

// Viewer URL with waiting screen
const viewerUrl = computed(() =>
  state.data && state.data.file && state.data.file.viewer_url
    ? appendWaitingScreen(state.data.file.viewer_url)
    : ''
)

// Current asset data
const currentAssetData = computed(() => {
  if (!state.data) return null
  return state.data.file
})

// View switch handlers
const handleFetchSingle = () => {
  if (currentViewType.value === 'single') return
  currentViewType.value = 'single'
  if (token.value) loadApprovalData(token.value, 'single')
}

const handleFetchStepRepeat = () => {
  if (currentViewType.value === 'stepRepeat') return
  currentViewType.value = 'stepRepeat'
  if (token.value) loadApprovalData(token.value, 'stepRepeat')
}

// Token param
const token = ref('')

// Watch for visibility changes to trigger polling update
watch(visibility, isVisible => {
  if (isVisible && token.value) {
    loadApprovalData(token.value, currentViewType.value)
  }
})

// On mount: load user, CSS, token, initial data, start polling
onMounted(async () => {
  try {
    userStore.value = await storeImports.user()

    await loadCssFiles()

    const tokenValue = route.params.token
    if (!tokenValue) {
      state.error = 'No approval token provided in the URL.'
      return
    }
    token.value = tokenValue

    // Initial load
    await loadApprovalData(token.value, currentViewType.value)

    // Start polling
    startPolling(30000)
  } catch (err) {
    console.error('Error during approval page init:', err)
    state.error = err.message || 'Failed to initialize approval page.'
  }
})

onUnmounted(() => {
  stopPolling()
})

const submitAction = async () => {
  if (!token.value || !selectedAction.value) return

  const payload = {
    action: selectedAction.value,
    comment: comment.value
  }

  await submitApprovalAction(token.value, payload)

  // Close the form accordion on success
  if (!state.error) {
    isActionFormOpen.value = false
  }
}

const loadCssFiles = async () => {
  if (cssImportsLoaded.value) return

  try {
    if (!userStore.value?.isAuthenticated) {
      await Promise.all([
        import('@/vendor/fomantic/dist/components/button.min.css'),
        import('@/vendor/fomantic/dist/components/accordion.min.css'),
        import('@/vendor/fomantic/dist/components/transition.min.css'),
        import('@/vendor/fomantic/dist/components/loader.min.css'),
        import('@/vendor/fomantic/dist/components/form.min.css'),
        import('@/vendor/fomantic/dist/components/message.min.css'),
        import('@/vendor/fomantic/dist/components/segment.min.css'),
        import('@/vendor/fomantic/dist/components/dimmer.min.css'),
        import('@/vendor/fomantic/dist/components/list.min.css'),
        import('@/vendor/fomantic/dist/components/header.min.css'),
        import('@/vendor/fomantic/dist/components/placeholder.min.css'),
        import('@/vendor/fomantic/dist/components/table.min.css')
      ])
    }
    cssImportsLoaded.value = true
  } catch (err) {
    console.error('Failed to load CSS files:', err)
    state.error = 'Some styles failed to load. The page may not display correctly.'
  }
}
</script>

<style lang="scss" scoped>
.approval-page {
  display: grid;
  grid-template-columns: 350px 1fr;
  height: calc(
    100vh - var(--header-height, 0px)
  ); // Use CSS variable for header height, fallback to 0px
  margin: 0;
  overflow: hidden;

  // Page loader container styles
  .page-loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;

    .ui.active.dimmer {
      background-color: rgba(255, 255, 255, 0.95);
      box-shadow: none;
    }

    .ui.large.text.loader {
      font-size: 1.2em;
      color: var(--primary-color, #4183c4);
      &:before {
        border-color: var(--primary-color, #4183c4) transparent transparent;
      }
      &:after {
        border-color: var(--primary-color, #4183c4) transparent transparent;
      }
    }
  }

  // When there's an error or during loading, we need to reset the grid layout
  &:has(.error-container),
  &:has(.loader-container) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader-container,
  .error-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .ui.message {
      max-width: 500px;
      margin: 0;
    }

    .ui.active.dimmer {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  .sidebar-container {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    border-right: 1px solid #ddd;
    position: relative;
    width: 350px; // Fixed width for the sidebar
    min-width: 350px; // Ensure minimum width is maintained
    max-width: 350px; // Ensure maximum width is maintained

    .ui.active.dimmer {
      z-index: 10;
    }

    :deep(.sidebar) {
      height: 100%;
      margin: 0;
      border-radius: 0;
    }
  }

  .viewer-container {
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e0e0e0;

    :deep(iframe),
    :deep(.viewer-component-root) {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
