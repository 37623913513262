import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/components/Authentication/Login.vue'
import ApprovalPage from '@/views/Approval/ApprovalPage.vue'
import authenticatedRoutes from './routes/authenticated'
import { storeImports } from '@/stores/storeImports'
import { appSettings } from '@/main'

// Shared route definition for approval page
const approvalRoute = {
  name: 'approval',
  path: '/approve/:token',
  component: ApprovalPage,
  meta: {
    title: 'Approval'
    // No 'auth' middleware needed here, accessible by guests and authenticated users
  }
};

// -----------------------------------------------------------------------------
// 1. Unauthenticated Routes
// -----------------------------------------------------------------------------
function createUnauthenticatedRoutes() {
  return [
    {
      name: 'login',
      path: '/',
      component: Login,
      meta: {
        title: 'Login'
      },
      beforeEnter: (to, from, next) => {
        // Check if there's a redirect path in localStorage
        const redirectPath = localStorage.getItem('auth_redirect_path');
        if (redirectPath) {
          // eslint-disable-next-line no-console
          console.log('[Router] Login route has redirect path:', redirectPath);
        }
        next();
      }
    },
    approvalRoute, // Use the shared route object
    {
      // Catch-all route redirects to login but saves the intended path
      path: '/:pathMatch(.*)*',
      redirect: (to) => {
        // Store the full path in localStorage
        const path = to.fullPath;
        
        // eslint-disable-next-line no-console
        console.log('[Router] 🔴 Unauthenticated access attempt to:', path);
        
        // Only store non-login paths
        if (path !== '/' && !path.includes('/login')) {
          // Store the path in localStorage for redirect after login
          localStorage.setItem('auth_redirect_path', path);
          
          // eslint-disable-next-line no-console
          console.log('[Router] ✅ Stored intended path in localStorage:', path);
          
          // Also set a flag in window to indicate we have a redirect path
          window.__hasRedirectPath = true;
        }
        
        return '/';
      }
    }
  ]
}

// -----------------------------------------------------------------------------
// 2. Authenticated Routes (Including Dynamic Routes from resourceModels)
// -----------------------------------------------------------------------------

// Cache management
const routerCache = {
  authenticated: null,
  systemStore: null,
  timestamp: null,
  invalidate() {
    this.authenticated = null;
    this.systemStore = null;
    this.timestamp = null;
  },
  isValid() {
    return this.timestamp && (Date.now() - this.timestamp < 3600000); // 1 hour
  }
};

// Create an unauthorized route object to be consistent across all router configurations
const unauthorizedRoute = {
  path: '/unauthorized',
  name: 'unauthorized',
  component: () => import('../views/Unauthorized.vue'),
  meta: {
    title: 'Unauthorized Access',
    middleware: ['auth'] // Still require auth, just not specific permissions
  }
};

async function createAuthenticatedRoutes(systemStoreInstance) {
  // If no systemStoreInstance is provided (e.g., called outside authenticated boot),
  // try using the cache or loading dynamically as a fallback.
  // However, the primary path expects a valid instance from authenticated.js.
  if (!systemStoreInstance) {
    // eslint-disable-next-line no-console
    console.warn('[Router] createAuthenticatedRoutes called without a systemStoreInstance. Attempting fallback...');
    if (routerCache.isValid() && routerCache.authenticated) {
      // eslint-disable-next-line no-console
      console.log('[Router] Using cached authenticated routes (fallback)');
      return routerCache.authenticated;
    }
    // eslint-disable-next-line no-console
    console.log('[Router] Loading system store dynamically (fallback)');
    systemStoreInstance = await storeImports.system();
    // Attempt to fetch data if empty, assuming it wasn't loaded yet
    if (systemStoreInstance.isSystemDataEmpty()) {
       try {
         // eslint-disable-next-line no-console
         console.log('[Router] Fallback: System data not loaded, fetching now');
         await systemStoreInstance.fetchSystemData();
       } catch (error) {
         // eslint-disable-next-line no-console
         console.error('[Router] Fallback: Error fetching system data:', error);
         // Return only static routes on fallback failure, but always include the unauthorized route
         return [...authenticatedRoutes, approvalRoute, unauthorizedRoute];
       }
    }
  }

  // At this point, systemStoreInstance should be valid and loaded (guaranteed by authenticated.js)
  const systemStore = systemStoreInstance;

  // Check if resourceModels exist (should be guaranteed by authenticated.js check)
  if (!systemStore || !Array.isArray(systemStore.resourceModels) || systemStore.resourceModels.length === 0) {
      // eslint-disable-next-line no-console
      console.error('[Router] CRITICAL: systemStore provided but resourceModels are missing or empty. This indicates a problem in the boot sequence.');
      // Return static routes as a safety measure, but this shouldn't happen.
      // Always include the unauthorized route
      return [...authenticatedRoutes, approvalRoute, unauthorizedRoute];
  }

  // eslint-disable-next-line no-console
  console.log(`[Router] Processing ${systemStore.resourceModels.length} resource models from provided store instance`);
 
  const validResourceModels = systemStore.resourceModels.filter(model =>
    model && typeof model === 'object' && model.table_name && model.slug
  );
 
  // eslint-disable-next-line no-console
  console.log(`[Router] Found ${validResourceModels.length} valid resource models for route generation`);

  const dynamicRoutes = validResourceModels.flatMap(createRoutesForModel);
  // eslint-disable-next-line no-console
  console.log('[Router] Generated dynamic routes:', JSON.stringify(dynamicRoutes.map(r => ({ name: r.name, path: r.path })), null, 2)); // Log generated routes names and paths

  // Add the shared approval route to the authenticated routes as well
  routerCache.authenticated = [...authenticatedRoutes, ...dynamicRoutes, approvalRoute, unauthorizedRoute];
  routerCache.timestamp = Date.now();

  // eslint-disable-next-line no-console
  console.log('[Router] Created', routerCache.authenticated.length, 'total authenticated routes (including static and dynamic)');

  return routerCache.authenticated;
}

// -----------------------------------------------------------------------------
// 3. Router Factory
// -----------------------------------------------------------------------------
function createAppRouter(allRoutes) {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: allRoutes,
    linkActiveClass: 'active',
    // Add strict path matching to avoid issues with trailing slashes
    strict: true
  })

  // Add improved navigation checks
  router.beforeEach((to, from, next) => {
    // Log navigation for debugging
    // console.log(`[Router] Navigation from "${from.fullPath}" to "${to.fullPath}"`)
    
    // Check if the route exists in our router configuration
    try {
      const matchedRoute = router.resolve(to)
      if (!matchedRoute || matchedRoute.matched.length === 0) {
        // Route doesn't exist in the router, redirect to dashboard
        // console.warn(`[Router] Route not found: ${to.path}, redirecting to dashboard`)
        next('/dashboard')
        return
      }
    } catch {
      // console.error('[Router] Error checking route:', error)
    }
    
    // Continue with navigation
    next()
  })

  // After each route, set page title using appSettings + meta.title
  router.afterEach((to) => {
    const appName = appSettings.name || 'Default App Name'
    const pageTitle = to.meta.title
    document.title = pageTitle ? `${appName} | ${pageTitle}` : appName
  })
  
  // Add navigation debugging
  router.beforeEach((to, from) => {
    // eslint-disable-next-line no-console
    console.log(`[Router] Navigation from "${from.fullPath}" to "${to.fullPath}"`);

    return true;
  });

  return router
}

// -----------------------------------------------------------------------------
// 4. Helpers to Create Dynamic Routes for Each Resource Model
// -----------------------------------------------------------------------------
const routeComponents = {
  index: () => import(/* webpackChunkName: "crud-index" */ '@/views/Search/Index.vue'),
  create: () => import(/* webpackChunkName: "crud-create-edit" */ '@/views/CRUD/CreateEdit/Index.vue'),
  edit: () => import(/* webpackChunkName: "crud-create-edit" */ '@/views/CRUD/CreateEdit/Index.vue'),
  show: () => import(/* webpackChunkName: "crud-show" */ '@/views/CRUD/Show/Index.vue')
}

const routePaths = {
  index: slug => `/${slug}/index`,
  create: slug => `/${slug}/create`,
  edit: slug => `/${slug}/:id/edit`,
  show: slug => `/${slug}/:id`
}

function createRoutesForModel(resourceModel) {
  if (!resourceModel || !resourceModel.table_name) {
    // eslint-disable-next-line no-console
    console.warn('[Router] Skipping invalid resourceModel in createRoutesForModel:', resourceModel);
    return []
  }
  // eslint-disable-next-line no-console
  console.log(`[Router] Creating routes for model: ${resourceModel.table_name} (Slug: ${resourceModel.slug})`);
  
  const routes = []
  const actions = ['index', 'create', 'edit', 'show']

  let createdCount = 0;
  actions.forEach((action) => {
    // Check both hyphenated and underscore formats
    const hasAccess = resourceModel[`crud-${action}`] ?? resourceModel[`crud_${action}`];
    if (hasAccess) {
      routes.push(createRoute(resourceModel, action));
      createdCount++;
    }
  })

  // eslint-disable-next-line no-console
  console.log(`[Router] Created ${createdCount} routes for model ${resourceModel.table_name}`);
  return routes
}

function createRoute(resourceModel, type) {
  const { tableName, slug, displayNameSingular, displayNamePlural } = {
    tableName: resourceModel.table_name,
    slug: resourceModel.slug,
    displayNameSingular: resourceModel.display_name_singular,
    displayNamePlural: resourceModel.display_name_plural
  }
  const resourceName = type === 'index' 
    ? (displayNamePlural || slug).toLowerCase()
    : (displayNameSingular || slug).toLowerCase()
  const action = type.charAt(0).toUpperCase() + type.slice(1)
  return {
    name: `${tableName}.${type}`,
    path: routePaths[type](slug),
    component: routeComponents[type],
    meta: {
      title: `${action} ${resourceName}`,
      middleware: ['auth'],
      // @TODO: Temp disabled, might be needed later
      // can: [`${tableName}-${type}`]
    },
    props: route => ({
      resourceModel,
      ...(type === 'index' && { keyword: route.query.keyword }),
      ...((type === 'edit' || type === 'show') && { modelId: route.params.id })
    })
  }
}

// -----------------------------------------------------------------------------
// 5. Optional: One-shot to create the "full" router in your main entry point
// -----------------------------------------------------------------------------
export async function createFullRouter() {
  const unauthenticated = createUnauthenticatedRoutes()
  const authenticated = await createAuthenticatedRoutes()
  
  // Make sure that the unauthorized route is always included when creating a full router
  // This ensures that the ACL system can always find the route to redirect to
  const allRoutes = [...unauthenticated, ...authenticated]
  
  // Create and return the router with all routes
  return createAppRouter(allRoutes)
}

export {
  createAppRouter,
  createUnauthenticatedRoutes,
  createAuthenticatedRoutes // Keep exporting it, might be used elsewhere
}
