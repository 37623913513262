import { reactive, watch } from 'vue'
import authenticatedApiClient from '@/services/authenticatedApiClient'
import backendApiClient from '@/services/backendApiClient'

export function useApproval(currentViewTypeRef, userStoreRef) {

  const state = reactive({
    data: null,
    actions: [],
    error: null,
    loading: false,
    submitting: false,
    isSubmitted: false,
  })

  let currentFetchController = null
  let currentFetchPromise = null
  let pollingIntervalId = null
  let lastToken = null

  function getApiClient() {
    return userStoreRef.value?.isAuthenticated ? authenticatedApiClient : backendApiClient
  }

  function syncViewTypeWithUrl() {
    const params = new URLSearchParams(window.location.search)
    const urlView = params.get('view')
    if (urlView && urlView !== currentViewTypeRef.value) {
      currentViewTypeRef.value = urlView
    }
    watch(currentViewTypeRef, (newVal) => {
      const params = new URLSearchParams(window.location.search)
      params.set('view', newVal)
      const newUrl = `${window.location.pathname}?${params.toString()}`
      window.history.replaceState({}, '', newUrl)
    })
  }

  syncViewTypeWithUrl()

  async function loadApprovalData(token, viewType) {
    if (!token) return
    lastToken = token

    if (currentFetchController) currentFetchController.abort()
    currentFetchController = new AbortController()
    const signal = currentFetchController.signal

    state.loading = true
    state.error = null

    const apiClient = getApiClient()

    const fetchPromise = apiClient.get(`/api/v1/approval-links/${token}?view=${viewType}`, { signal })
    currentFetchPromise = fetchPromise

    try {
      const response = await fetchPromise
      if (currentFetchPromise !== fetchPromise) return
      const data = response.data

      if (!data || typeof data !== 'object' || !data.file) {
        state.data = null
        state.actions = []
        state.error = 'Invalid approval data received from server.'
        return
      }

      state.data = data
      state.actions = Array.isArray(data.available_actions) ? data.available_actions : []
      state.isSubmitted = ['approved', 'rejected'].includes(data.status)
      state.error = null
    } catch (err) {
      if (err.name !== 'AbortError') {
        console.error('Approval fetch error:', err)
        state.error = err.response?.data?.message || err.message || 'Failed to load approval data.'
      }
    } finally {
      if (currentFetchPromise === fetchPromise) {
        state.loading = false
      }
    }
  }

  async function submitApprovalAction(token, payload) {
    if (!token) return

    state.submitting = true
    state.error = null

    const apiClient = getApiClient()

    try {
      await apiClient.post(`/api/v1/approval-links/${token}/submit`, payload)
      await loadApprovalData(token, currentViewTypeRef.value)
    } catch (err) {
      console.error('Approval submit error:', err)
      state.error = err.response?.data?.message || err.message || 'Failed to submit approval action.'
    } finally {
      state.submitting = false
    }
  }

  function startPolling(intervalMs = 30000) {
    stopPolling()
    pollingIntervalId = setInterval(() => {
      if (lastToken && !state.isSubmitted) {
        loadApprovalData(lastToken, currentViewTypeRef.value)
      }
    }, intervalMs)
  }

  function stopPolling() {
    if (pollingIntervalId) clearInterval(pollingIntervalId)
    pollingIntervalId = null
  }

  return {
    state,
    loadApprovalData,
    submitApprovalAction,
    startPolling,
    stopPolling,
  }
}